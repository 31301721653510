import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Fencing = () => (
  <Layout>
    <Helmet title="Pool" />
    <BgImage image="/assets/outdoor-living.jpg" className="top">
      <h1>Fencing</h1>
    </BgImage>
    <section className="content">
      <p>
        Pool fencing adds a touch of style, class and value to your home while
        still offering the highest safety standards for your family. All of our
        fencing complies with Australian Pool Safety Standards with all
        materials fully certified.
      </p>
      <p>
        Pool fencing is essential to prevent small children or pets from
        accidentally falling into the swimming pool in the backyard. A properly
        designed pool fence not only makes swimming safer for young swimmers and
        prevent accidents, but can also enhance the beauty of the area. As with
        any other aspect of the pool one needs to take careful consideration in
        choosing the correct fence to ensure the backyard is enhanced to its
        full potential. We provide a range of different fencing options
        including:
      </p>
      <ul>
        <li>Frameless Glass Fences</li>
        <li>Semi-Frameless Glass Fences</li>
        <li>Steel &amp; Aluminum Fencing</li>
        <li>Wooden Fencing</li>
      </ul>
    </section>
  </Layout>
)

export default Fencing
